<template>
  <div class="system_doc_list">
    <div class="top_bg">
      <div class="top_item" :class="[selected == 0 ? 'selected':'']" @click="onClick(itemPrice, 0)">
        <img src="../assets/img/sys_doc.png" alt="">
        <div class="info">
          <p class="title">5分钟快速问诊</p>
          <p class="hospital">二级甲以上医院医生</p>
          <!-- <p class="people">2万人已购买</p> -->
        </div>
        <div class="price">
          <p>
            <span>￥</span>
            <span>{{itemPrice.price}}</span>
            <span>/次</span>
          </p>
          <p>20次对话</p>
        </div>
      </div>
    </div>
    <div class="active_item" >
      <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white">
        <van-swipe-item v-for="item in currentList" :key="item.activityId">
          <div class="img" @click="goToYiYuan(item)">
            <img :src="item.smallBannerPic" alt="">
            <!-- <p>{{item.activityName}}</p> -->
            <!-- <p>医生为您的健康指导</p> -->
          </div>
        </van-swipe-item>
        <!-- <van-swipe-item>2</van-swipe-item> -->
      </van-swipe>
    </div>
    <div class="list_title">
      <p>指定专家医生</p>
    </div>
    <div class="list">
      <div class="item" :class="[selected == index + 1 ? 'selected':'']" v-for="(item, index) in docList" @click="onClick(item, (index + 1))" :key="index">
        <img :src="item.image" alt="">
        <div class="info">
          <p class="name">
            <span>{{item.name}}</span>
            <span>{{item.title}}</span>
            <span>{{item.hospital_grade}}</span>
          </p>
          <p class="hospital">
              <span>{{item.hospital_name}}</span>
             <span>{{item.clinic_name}}</span>
          </p>

          <p class="describe">擅长：{{item.good_at}}</p>
        </div>
        <div class="price">
          <p>
            <span>￥</span>
            <span>{{item.price}}</span>
            <span>/次</span>
          </p>
           <p class="item_bottom">
            <span>{{item.purchase_num}}</span>
            <span>人已选择</span>
          </p>
          <!-- <p>限20次对话</p> -->
        </div>
      </div>
    </div>
    <!-- <div class="bottom_btn">
      <button @click="submit">提交(￥{{price}})</button>
    </div> -->
     <div class="under">
      <div class="under_left">
        <span>服务价格: </span>
        <span>¥{{price}}</span>
      </div>
      <div class="under_right">
        <button @click="submit">立即问诊</button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      selected: 0,
      docList: [],
      price: this.$store.state.fastPrice.toFixed(2),
      // fastPrice: 5,
      itemPrice: {
        price: 5
      },
      currentList: []
    }
  },
  created() {
    this.$store.dispatch('setPrice');
    this.list();
    this.getList()
    this.itemPrice.price = this.$store.state.fastPrice.toFixed(2);
  },
  methods: {
    // 获取当前活动列表数据
    getList() {
      this.$http('post','/server/activityInfo/current',{}, 1).then(res => {
        // // console.log(res, 'res======>');
        this.currentList = res.data.data
      })
    },
    // 前往一元问诊
    goToYiYuan(item) {
      this.$store.state.visitsType = 4;
      this.$store.commit('setActiveId',item.activityId)
      this.$router.push({
        // path: "/special_one_yuan",
        path: "/" + item.activityUrl,
        query: { id: item.activityId },
      });
    },
    // 推荐医生
    list() {
      let params = {
        ask: this.$store.state.message
      }
      this.$http('post', '/cooperation/server/doctor/get_recommended_doctors', params).then(res => {
        // console.log(res)
        if (res.data.error == 0) {
          this.docList = res.data.doctors;
          this.docList.forEach(item => {
            item.price = (item.price * (1 + this.$store.state.floatRate) / 100).toFixed(2)
          })
        }
      })
    },
    onClick(item, index) {
      this.selected = index;
      this.price = item.price;
      this.$store.state.docDetail = item;
    },
    submit() {
      // 清除活动
      this.$store.commit('resetActiveId')
      if (this.selected == 0) {
        this.$router.push('/fast_order')
      } else {
        this.$store.state.docType == 2
        this.$router.push('/directional_order')
      }
    }
  }
}
</script>
<style lang="less" scoped>
.system_doc_list {
  min-height: calc(100% - 0.14rem);
  background: #F3F3F3;;
  // padding-top: 0.14rem;
  //   mamrgin-top: 0.14rem;
  // padding: 0 0.2rem 0 0.28rem;
  .list {
    padding-bottom: 1.8rem;

    .selected {
      border: 1px solid #ffc025;
    }
  }
    .item,
    .top_item {
      display: flex;
      align-items: center;
      background: #fff;
      border-radius: 2px;
      padding: 0.2rem 0.22rem;
      margin: 0rem 0.24rem 0.14rem 0.24rem;
      border: 1px solid transparent;
      img {
        width: 1.22rem;
        height: 1.22rem;
        margin-right: 0.22rem;
      }
      .info {
        flex: 1;
        .title {
          font-size: 0.28rem;
          color: #333;
          margin-bottom: 0.12rem;
        }
        .name {
          margin-bottom: 0.12rem;
          span:nth-child(1) {
            font-size: 0.3rem;
            color: #333;
            font-weight: 500;
          }
          span:nth-child(2),
          span:nth-child(3) {
            font-size: 0.24rem;
            color: #666666;
            margin-left: 0.16rem;
          }
          span:nth-child(3) {
            color: #FFFFFF;
            display: inline-block;
            // width: 0.6rem;
            // height: 0.38rem;
            background: linear-gradient(180deg, #F6CC97 0%, #DAAE6D 100%);
            border-radius: 0.04rem;
            padding: 0.02rem ;
          }
        }
        .describe {
          font-size: 0.2rem;
          margin-bottom: 0.12rem;
          color: #999999;
          width: 3.5rem;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 1; /* 这里是超出几行省略 */
          overflow: hidden;
        }
        .item_bottom {
          font-size: 0.22rem;
          span:nth-child(1) {
            color: #666666;
            margin-right: 0.3rem;
          }
          span:nth-child(2) {
            color: #ffc025;
          }
          span:nth-child(3) {
            color: #666666;
          }
        }
        .hospital {
          font-size: 0.24rem;
          color: #666666;
          margin-bottom: 0.12rem;
          span {
            margin-right: 6px;
          }
        }
        .people:nth-child(3) {
          font-size: 0.2rem;
          color: #ffc025;
        }
      }
      .price {
        p:nth-child(1) {
          span:nth-child(1),
          span:nth-child(3) {
            font-size: 0.24rem;
          }
          span:nth-child(2) {
            font-size: 0.36rem;
          }
        }
        p:nth-child(2) {
          // font-size: 0.2rem;
          // color: #999999;
          span:nth-child(1) {
            color: #ffc025;
          }
        }
      }
    }
  .top_bg {
    height: 1.4rem;
    width: 100%;
    background-color: #2F8EFF;
    .top_item {
      transform: translateY(0.40rem);
    }
    .selected {
      border: 1px solid #ffc025;
    }
    .info {
      p:nth-child(1) {
        font-size: 0.3rem;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #2F8EFF;
        line-height: 0.42rem;
      }
      p:nth-child(2) {
        font-size: 0.3rem;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #333333;
        line-height: 0.42rem;
      }
    }
  }
  .active_item {
    padding: 1rem 0.2rem 0.4rem 0.28rem;
    .img>img {
      width: 100%;
      height: 100%;
    }
    .img {
      position: relative;
      height: 1.82rem;
      p:nth-child(2) {
       line-height: 0.6rem;
        letter-spacing: 5px;
        text-shadow: 0px 0px 0px #0063DB;
        font-size: 0.5rem;
        font-weight: 600;
        color: #FFFFFF;
        margin-bottom: 0.1rem;
        position: absolute;
        top: 0.4rem;
        left: 0.94rem;
    }
    p:nth-child(3) {
        text-shadow: 0px 0px 0px #2E97F2;
        font-size: 0.3rem;
        font-weight: normal;
        position: absolute;
        top: 1.04rem;
        left: 0.64rem;
        color: #FFFFFF;
        line-height: 0.42rem;
        letter-spacing: 3px;
      }
    }
  }
  .list_title {
    padding: 0 0.2rem 0.16rem 0.28rem;
    p {
      font-size: 0.32rem;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 600;
      color: #333333;
      line-height: 0.44rem;
    }
  }
  .bottom_btn {
    position: fixed;
    bottom: 0;
    left: 0;
    background: #fff;
    padding: 0.4rem;
    width: calc(100% - 0.8rem);
    button {
      height: 0.72rem;
      line-height: 0.72rem;
      width: 100%;
      border: 0;
      color: #fff;
      font-size: 0.32rem;
      background: #42cec8;
      border-radius: 4px;
    }
  }
      .under {
      height: 1.18rem;
      position: fixed;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: #FFFFFF;
      height: 1.18rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 0.26rem 0 0.4rem;
      .under_right>button {
        width: 1.96rem;
        height: 0.68rem;
        border-radius: 0.76rem;
        color: #FFFFFF;
        line-height: 0.48rem;
        font-weight: 500;
        font-size: 0.32rem;
        background: linear-gradient(270deg, #FF9F22 0%, #FF8D3A 100%);
        border: none;
        // padding: 0.18rem 0.34rem;
      }
      .under_left {
        span:first-child {
          font-size: 0.28rem;
          color: #333333;
          line-height: 0.42rem;
        }
        span:last-child {
          font-size: 0.32rem;
          font-weight: 500;
          color: #FFA438;
          line-height: 0.48rem;
        }
      }
    }
}
</style>
